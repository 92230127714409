<template>
  <b-modal
    id="add-model-modal"
    v-model="open"
    hide-footer
    size="lg"
    centered
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
  >
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      rounded="sm"
      variant="transparent"
      style="flex: 1"
      class="d-flex flex-column"
    >
      <h1 class="text-center">{{ $t(title) }}</h1>
      <feather-icon
        class="cursor-pointer position-absolute"
        style="top: 0; right: 1rem"
        icon="XIcon"
        size="22"
        @click="closeModal"
      />
      <form-wizard
        ref="wizard"
        :title="null"
        :subtitle="null"
        layout="vertical"
        :finish-button-text="id ? $t('Done') : $t('Submit')"
        :back-button-text="$t('Previous')"
        :next-button-text="$t('Next')"
        class="wizard-vertical mb-1"
        @on-complete="id ? closeModal() : submit()"
      >
        <tab-content
          :title="$t('Model Details')"
          :before-change="validateTabOne"
        >
          <b-row>
            <b-col
              cols="8"
              class="mb-2"
            >
              <h5 class="mb-0">{{ $t('Model Details') }}</h5>
              <small class="text-muted">
                {{ $t('Manage model details') }}
              </small>
            </b-col>
            <b-col sm="12">
              <b-form-group
                :label="$t('Model Type')"
                label-for="model-type"
              >
                <b-form-radio
                  v-model="type"
                  class="mt-1"
                  name="type"
                  value="FIXED"
                  :disabled="Boolean(id)"
                >
                  <h4 class="mb-0">{{ $t('Amount Based') }}</h4>
                  <small class="text-muted">{{ $t('Commission is based on amount of X') }}</small>
                </b-form-radio>
                <b-form-radio
                  v-model="type"
                  class="mt-1"
                  value="PERCENTAGE"
                  name="type"
                  :disabled="Boolean(id)"
                >
                  <h4 class="mb-0">{{ $t('Percentage Based') }}</h4>
                  <small class="text-muted">{{ $t('Commission is based on a percentage of a total amount') }}</small>
                </b-form-radio>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                :label="$t('Model Name')"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="name"
                  :placeholder="$t('Model Name')"
                />
                <b-form-invalid-feedback
                  v-if="errorMsg && errorMsg.field === 'name'"
                  id="agreementType"
                >
                  {{ errorMsg.msg }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label-for="agreementType"
                :label="$t('Agreement Type')"
              >
                <v-select
                  id="agreementType"
                  v-model="agreementType"
                  :options="agreementTypes"
                  label="text"
                  :placeholder="$t('Agreement Type')"
                >
                  <template slot="no-options">
                    {{ $t('Sorry, no matching options') }}
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  v-if="errorMsg && errorMsg.field === 'agreementType'"
                  id="agreementType"
                >
                  {{ errorMsg.msg }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col sm="12">
              <b-form-group
                label-for="category"
                :label="$t('Categories')"
              >
                <v-select
                  id="category"
                  v-model="category"
                  :options="categories"
                  label="text"
                  :reduce="e => e.value"
                  :placeholder="$t('Categories')"
                >
                  <template slot="no-options">
                    {{ $t('Sorry, no matching options') }}
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  v-if="errorMsg && errorMsg.field === 'category'"
                  id="category"
                >
                  {{ errorMsg.msg }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col sm="12">
              <b-form-group
                label-for="year"
                :label="$t('Year')"
              >
                <v-select
                  id="year"
                  v-model="year"
                  :options="yearSelect"
                  label="text"
                  :reduce="e => e.value"
                  :placeholder="$t('Year')"
                >
                  <template slot="no-options">
                    {{ $t('Sorry, no matching options') }}
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  v-if="errorMsg && errorMsg.field === 'year'"
                  id="category"
                >
                  {{ errorMsg.msg }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col sm="12">
              <b-form-group
                :label="$t('Description')"
                label-for="description"
              >
                <b-form-input
                  id="description"
                  v-model="description"
                  :placeholder="$t('Description')"
                />
                <b-form-invalid-feedback
                  v-if="errorMsg && errorMsg.field === 'description'"
                  id="description"
                >
                  {{ errorMsg.msg }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

          </b-row>
        </tab-content>
        <tab-content :title="$t('Product Details')">
          <b-row>
            <b-col
              cols="8"
              class="mb-2"
            >
              <h5 class="mb-0">{{ $t('Product Details') }}</h5>
              <small class="text-muted">
                {{ $t('Add model products') }}
              </small>
            </b-col>
            <b-col class="d-flex justify-content-end align-items-start">
              <b-dropdown size="md">
                <template #button-content>
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-1"
                  />
                  <span class="text-nowrap">{{ $t('Add Product') }}</span>
                </template>
                <b-dropdown-text style="width: 200px"> {{ $t('Select one of the following products') }} </b-dropdown-text>
                <b-dropdown-item
                  v-for="item in filteredModelProducts"
                  :key="item.uuid_key"
                  @click="addProduct(item)"
                >
                  {{ item.name }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
          <div>
            <b-tabs>
              <b-tab
                v-for="(product, productIndex) in products"
                :key="productIndex"
                :title="product.name"
              >
                <template #title>
                  {{ product.name }}
                  <div v-if="product.edited">
                    <feather-icon
                      :id="`tooltip-${productIndex}-save`"
                      class="ml-1 cursor-pointer"
                      icon="SaveIcon"
                      size="14"
                      @click="updateProduct(product)"
                    />
                    <b-tooltip
                      :target="`tooltip-${productIndex}-save`"
                      :title="$t('Save')"
                      placement="top"
                    />
                  </div>
                  <!-- Delete Button -->
                  <div v-if="products.length > 1">
                    <feather-icon
                      :id="`tooltip-${productIndex}-delete`"
                      class="ml-1 cursor-pointer"
                      icon="XIcon"
                      size="14"
                      @click.prevent="removeProduct(product, productIndex)"
                    />
                    <b-tooltip
                      :target="`tooltip-${productIndex}-delete`"
                      :title="$t('Remove')"
                      placement="top"
                    />
                  </div>
                </template>
                <b-row>
                  <b-col
                    cols="8"
                    class="mb-2"
                  >
                    <b-form-group
                      :label="$t('Change Product')"
                      label-for="change-product-select"
                    >
                      <v-select
                        id="change-product-select"
                        :value="product.name"
                        :options="filteredModelProducts"
                        :reduce="product => product.name"
                        label="name"
                        :placeholder="$t('Change Product')"
                        @option:selected="d => handleModelProductUuidChange(d, product)"
                      >
                        <template slot="no-options">
                          {{ $t('Sorry, no matching options') }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mt-n2 mb-1"
                  >
                    <hr style="opacity: 0.4" />
                  </b-col>
                </b-row>
                <div>
                  <b-card
                    no-body
                    class="shadow-none mt-n1"
                  >
                    <b-tabs>
                      <b-tab
                        :title="$t('Levels')"
                        active
                      >
                        <b-row>
                          <b-col class="d-flex justify-content-end align-items-start">
                            <b-button
                              id="add-level"
                              size="md"
                              variant="flat-primary"
                              class="btn-icon mb-1"
                              @click.prevent="addLevel(productIndex)"
                            >
                              <feather-icon icon="PlusIcon" />
                              <span class="text-nowrap">{{ $t('Add Level') }}</span>
                            </b-button>
                          </b-col>
                          <b-col cols="12">
                            <div
                              v-if="product.modelLevels.length === 0"
                              class="text-center text-muted mb-1"
                            >
                              {{ $t('Add a new levels using the + Add Levels button above') }}
                            </div>
                            <div
                              class="collapse-icon collapse-border"
                              style="margin-left: -16px; margin-right: -12px"
                            >
                              <b-col
                                cols="12"
                                class="container"
                              >
                                <LevelItem
                                  v-for="(level, levelIndex) in product.modelLevels"
                                  :key="levelIndex"
                                  :index="levelIndex"
                                  :match="cachedModel?.products[productIndex]?.modelLevels[levelIndex]"
                                  :type="type"
                                  :is-visible="false"
                                  :level="level"
                                  :edit-icon="false"
                                  :show-buttons="true"
                                  :show-collapse="true"
                                  :show-delete="product.modelLevels.length > 1"
                                  @delete="deleteLevel(level)"
                                  @save="updateLevel(level)"
                                />
                              </b-col>
                            </div>
                            <b-form-invalid-feedback
                              v-if="errorMsg?.field === 'levels'"
                              class="text-center mb-1"
                            >
                              {{ errorMsg.msg }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                      </b-tab>
                      <b-tab
                        :disabled="products.length === 1"
                        :title="$t('Conditions')"
                      >
                        <b-row class="mt-2">
                          <b-col
                            cols="12"
                            md="6"
                          >
                            <b-form-group
                              :label="$t('Select Product')"
                              label-for="condition-product-select"
                            >
                              <v-select
                                id="condition-product-select"
                                :value="product.productCondition"
                                :options="products.filter(p => p.id !== product.id)"
                                :reduce="product => product.id"
                                label="name"
                                max-height="50px"
                                :placeholder="$t('Select Product')"
                                @input="d => handleConditionProduct(d, product)"
                              >
                                <template slot="no-options">
                                  {{ $t('Sorry, no matching options') }}
                                </template>
                              </v-select>
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            md="6"
                          >
                            <b-form-group
                              :label="$t('Select Level')"
                              label-for="condition-level-select"
                            >
                              <v-select
                                id="condition-level-select"
                                v-model="product.levelCondition"
                                :options="products.find(p => p.id === product.productCondition)?.modelLevels || []"
                                :get-option-label="option => option.name"
                                :reduce="product => product.id"
                                label="name"
                                :disabled="!product.productCondition || product.mirrorProduct"
                                :placeholder="$t('Select Level')"
                              >
                                <template slot="no-options">
                                  {{ $t('Sorry, no matching options') }}
                                </template>
                              </v-select>
                              <b-form-checkbox
                                v-model="product.mirrorProduct"
                                class="mt-1"
                              >
                                {{ $t('Mirror a product') }}
                              </b-form-checkbox>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </div>
              </b-tab>
              <!-- Render this if no tabs -->
              <template #empty>
                <div class="text-center text-muted mb-1">
                  {{ $t('There are no products') }}<br />
                  {{ $t('Add a new product using the + Add Product button above') }}
                </div>
                <b-form-invalid-feedback
                  v-if="errorMsg?.field === 'products'"
                  class="text-center mb-1"
                >
                  {{ errorMsg.msg }}
                </b-form-invalid-feedback>
              </template>
            </b-tabs>
          </div>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </b-modal>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { customEntityRowsService } from '@/services/customEntityData'
import VueSlider from 'vue-slider-component'
import '@core/scss/vue/libs/vue-slider.scss'
import { BRow, BCol, BFormGroup, BFormInput, BModal, BFormCheckbox, BForm, BButton, BFormSpinbutton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import modelsServices from '@/services/models'
import LevelItem from './LevelItem.vue'
import { metastatusService } from '@/services/metastatus'
import MetastatusTypes from '@/constants/metastatusTypes'

export default {
  components: {
    LevelItem,
    BModal,
    VueSlider,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSpinbutton,
    ToastificationContent,
  },
  data() {
    return {
      cachedModel: null,
      products: [],
      productCount: 1,
      id: null,
      uuidKey: null,
      open: false,
      type: 'FIXED',
      loading: false,
      name: '',
      agreementType: '',
      description: '',
      sliderLabel: 'Range',
      tableName: 'model_product',
      title: 'Create Model',
      agreementTypes: [],
      errorMsg: null,
      initialized: false,
      modelProductUuid: null,
      allModelProducts: [],
      category: null,
      categories: [],
      year: new Date().getFullYear(),
      yearSelect: Array.from({ length: 3 }, (_, i) => new Date().getFullYear() + i).map(year => ({
        text: year,
        value: year,
      })),
    }
  },
  computed: {
    filteredModelProducts() {
      return this.allModelProducts.filter(p => this.products.every(item => item.name !== p.name))
    },
  },
  watch: {
    products: {
      deep: true,
      handler(newProducts, oldProducts) {
        if (newProducts.length > 0 && newProducts.every(p => p.modelLevels.length > 0)) {
          this.errorMsg = null
        }

        if (!this.initialized) return

        const { products } = this.cachedModel

        if (!products) return

        for (let i = 0; i < newProducts.length; i++) {
          const match = products.find(item => item.uuidKey === newProducts[i].uuidKey)

          if (match) {
            const newName = newProducts[i].name
            const newProductCondition = newProducts[i].productCondition
            const newLevelCondition = newProducts[i].levelCondition
            const newMirrorProduct = newProducts[i].mirrorProduct

            if (
              match.name !== newName ||
              match.productCondition !== newProductCondition ||
              match.levelCondition !== newLevelCondition ||
              match.mirrorProduct !== newMirrorProduct
            ) {
              this.products[i].edited = true
            } else {
              this.products[i].edited = false
            }
          }
        }
      },
    },
    type: {
      handler(newType, oldType) {
        this.sliderLabel = newType === 'FIXED' ? 'Range' : 'Percentage (%)'
        if (this.id) return
        this.products = []
      },
    },
  },

  async mounted() {
    const categoriesResponse = await metastatusService(MetastatusTypes.CATEGORY).getAll()
    if (categoriesResponse?.data?.pageItems) {
      this.categories = categoriesResponse.data.pageItems.filter(
        v => {
          const entityIndex = v.entities.findIndex(e => (e.schemaName === 'crm' && e.tableName === 'model'))
          if (entityIndex >= 0 && this.title !== 'Edit Model' && v.entities[entityIndex].isDefaultValue) {
            this.category = v.metaStatusId
          }
          return entityIndex >= 0
        },
      ).map(m => ({ text: m.metaStatusName, value: m.metaStatusId }))
    }
  },

  methods: {
    async updateProduct(p) {
      const modelProductUuid = p.modelProductUuid || this.getModelProductUuidByName(p.name)

      if (!modelProductUuid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: 'Product is required',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      this.loading = true
      const payload = {
        name: p.name,
        modelId: this.id,
        modelProductUuid,
        productCondition: p.productCondition,
        levelCondition: p.levelCondition,
        mirrorProduct: p.mirrorProduct,
      }

      try {
        const data = await modelsServices.updateProduct(p.uuidKey, payload)

        this.products = this.products.map(product =>
          product.uuidKey === p.uuidKey
            ? {
                ...product,
                name: p.name,
                productCondition: p.productCondition,
                levelCondition: p.levelCondition,
                mirrorProduct: p.mirrorProduct,
                edited: false,
              }
            : product,
        )

        this.cachedModel = {
          ...this.cachedModel,
          products: this.cachedModel.products.map(product =>
            product.uuidKey === p.uuidKey
              ? {
                  ...product,
                  name: p.name,
                  productCondition: p.productCondition,
                  levelCondition: p.levelCondition,
                  mirrorProduct: p.mirrorProduct,
                  edited: false,
                }
              : product,
          ),
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          await this.$swal({
            title: this.$t('Warning'),
            text: this.$t(`${error.response.data.message}`),
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: this.$t('Ok'),
            customClass: {
              cancelButton: 'btn btn-outline-primary ml-1',
            },
            buttonsStyling: false,
          })
        }
      } finally {
        this.loading = false
      }
    },

    async updateLevel(level) {
      this.loading = true

      const payload = {
        name: level.name,
        amount: level.amount,
        lowerBound: level.lowerBound,
        upperBound: level.upperBound,
        productId: level.productId,
      }

      try {
        await modelsServices.updateLevel(level.uuidKey, payload)

        this.products = this.products.map(product =>
          product.id === level.productId
            ? { ...product, modelLevels: product.modelLevels.map(l => (level.uuidKey === l.uuidKey ? { ...level, edited: false } : l)) }
            : product,
        )

        this.cachedModel = {
          ...this.cachedModel,
          products: this.cachedModel.products.map(product =>
            product.id === level.productId
              ? { ...product, modelLevels: product.modelLevels.map(l => (level.uuidKey === l.uuidKey ? { ...level, edited: false } : l)) }
              : product,
          ),
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async updateModelDetails() {
      const payload = {
        name: this.name,
        agreementType: this.agreementType,
        categoryId: this.category,
        year: this.year,
        description: this.description,
      }
      this.loading = true
      try {
        await modelsServices.updateModel(this.uuidKey, payload)
        this.cachedModel.name = this.name
        this.cachedModel.agreementType = this.agreementType
        this.cachedModel.categoryId = this.category
        this.cachedModel.description = this.description
        this.cachedModel.year = this.year
        this.$emit('submit', payload)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async getModelProducts() {
      this.loading = true
      try {
        const { data } = await customEntityRowsService(this.tableName).getAll()
        if (data) {
          this.allModelProducts = data.pageItems.map(item => item.values)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async validateTabOne() {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          if (this.name === '') {
            reject(
              new Error(
                (this.errorMsg = {
                  field: 'name',
                  msg: this.$t('This is a required field'),
                }),
              ),
            )
          } else if (this.agreementType === '') {
            reject(
              new Error(
                (this.errorMsg = {
                  field: 'agreementType',
                  msg: this.$t('This is a required field'),
                }),
              ),
            )
          } else if (this.description.length > 200) {
            reject(
              new Error(
                (this.errorMsg = {
                  field: 'description',
                  msg: this.$t('Character limit reached: Max is 200.'),
                }),
              ),
            )
          } else {
            if (this.id) {
              if (this.cachedModel.name !== this.name || this.cachedModel.agreementType !== this.agreementType || this.cachedModel.categoryId !== this.category || this.cachedModel.year !== this.year || this.cachedModel.description !== this.description) {
                await this.updateModelDetails()
              }
            } else {
              await this.addModel()
            }
            this.errorMsg = null
            resolve(true)
          }
        }, 100)
      })
    },

    async addModel() {
      const payload = {
        name: this.name,
        type: this.type,
        agreementType: this.agreementType,
        categoryId: this.category,
        year: this.year,
        description: this.description,
      }

      this.loading = true

      try {
        const { data } = await modelsServices.addModel(payload)
        if (data) {
          const { id, uuidKey, name, type, agreementType, products, categoryId, year, description } = data

          this.cachedModel = data
          this.products = products.map(product => ({
            ...product,
            modelLevels: product.modelLevels.map(level => ({
              ...level,
              edited: false,
            })),
          }))
          this.id = id
          this.uuidKey = uuidKey
          this.name = name
          this.type = type
          this.agreementType = agreementType
          this.category = categoryId
          this.year = year
          this.description = description
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Model Created'),
            text: 'Model succesfully created. Add products to the model',
            icon: 'CheckIcon',
            variant: 'primary',
          },
        })

        this.$emit('submit', payload)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async addProduct(item) {
      this.loading = true

      const payload = {
        modelId: this.id,
        modelProductUuid: item.uuid_key,
        productCondition: null,
        levelCondition: null,
        mirrorProduct: false,
        modelLevels: [],
      }

      try {
        const { data } = await modelsServices.addProduct(payload)
        this.products = [
          ...this.products,
          {
            ...data,
            modelLevels: data.modelLevels.map(level => ({
              ...level,
              edited: false,
            })),
          },
        ]

        this.cachedModel = {
          ...this.cachedModel,
          products: [
            ...this.cachedModel.products,
            {
              ...data,
              modelLevels: data.modelLevels.map(level => ({
                ...level,
                edited: false,
              })),
            },
          ],
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async addLevel(productIndex) {
      this.loading = true

      const selectedProduct = this.products[productIndex]

      const payload = {
        modelId: this.id,
        productId: selectedProduct.id,
        name: `Level ${selectedProduct.modelLevels.length + 1}`,
        amount: 0,
        ...(this.type === 'FIXED'
          ? {
              lowerBound: 0,
              upperBound: 10,
            }
          : {
              lowerBound: null,
              upperBound: 10,
            }),
      }

      try {
        const { data } = await modelsServices.addLevel(payload)

        if (data) {
          this.products = this.products.map(product =>
            product.id === selectedProduct.id
              ? {
                  ...product,
                  modelLevels: [
                    ...product.modelLevels,
                    {
                      ...data,
                      edited: false,
                    },
                  ],
                }
              : product,
          )

          this.cachedModel = {
            ...this.cachedModel,
            products: this.cachedModel.products.map(product =>
              product.id === selectedProduct.id
                ? {
                    ...product,
                    modelLevels: [
                      ...product.modelLevels,
                      {
                        ...data,
                        edited: false,
                      },
                    ],
                  }
                : product,
            ),
          }

          // Introduce a slight delay before scrolling
          setTimeout(() => {
            const newItem = document.getElementById(`level-${data.id - 1}`)

            if (newItem) {
              newItem.scrollIntoView({
                behavior: 'smooth',
              })
            }
          }, 200)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    handleConditionProduct(value, product) {
      this.products = this.products.map(p => (p.id === product.id ? { ...p, productCondition: value } : p))
      this.cachedModel = { ...this.cachedModel, products: this.products.map(p => (p.id === product.id ? { ...p, productCondition: value } : p)) }
    },

    handleModelProductUuidChange(selectedProduct, product) {
      this.products = this.products.map(p => (p.id === product.id ? { ...p, name: selectedProduct.name, modelProductUuid: selectedProduct.uuid_key } : p))
    },

    getModelProductUuidByName(name) {
      return this.allModelProducts.find(p => p.name === name)?.uuid_key
    },

    async removeProduct(item, index) {
      if (item.uuidKey) {
        if (this.$can('Delete', 'CRM') === false) return

        if (this.products.length === 1) {
          await this.$swal({
            title: this.$t('Warning'),
            text: this.$t('Cannot be removed, atleast one product is required'),
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: this.$t('Ok'),
            customClass: {
              cancelButton: 'btn btn-outline-primary ml-1',
            },
            buttonsStyling: false,
          })

          return
        }

        this.loading = true

        const res = await this.$swal({
          title: this.$t('Warning'),
          text: this.$t('Are you sure you want to remove?'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        })

        if (!res.value) {
          this.loading = false
          return
        }

        try {
          await modelsServices.removeProduct(item.uuidKey)
          this.products = this.products.filter(product => product.uuidKey !== item.uuidKey)
          this.cachedModel = { ...this.cachedModel, products: this.products.filter(product => product.uuidKey !== item.uuidKey) }
        } catch (error) {
          console.log(error)
        } finally {
          this.loading = false
        }
      } else {
        this.products.splice(index, 1)
      }
    },

    async deleteLevel(item) {
      if (this.$can('Delete', 'CRM') === false) return

      if (this.products.find(p => p.id === item.productId)?.length === 1) {
        await this.$swal({
          title: this.$t('Warning'),
          text: this.$t('Cannot be deleted, atleast one level is required'),
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: this.$t('Ok'),
          customClass: {
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        })

        return
      }

      const res = await this.$swal({
        title: this.$t('Warning'),
        text: this.$t('Are you sure you want to remove?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      })

      if (!res.value) return

      this.loading = true

      try {
        await modelsServices.deleteLevel(item.uuidKey)
        this.products = this.products.map(product => ({
          ...product,
          modelLevels: product.modelLevels.filter(level => level.uuidKey !== item.uuidKey),
        }))
        this.cachedModel = { ...this.cachedModel, modelLevels: this.levels.filter(level => level.uuidKey !== item.uuidKey) }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async closeModal() {
      if (this.id) {
        if (this.products.some(l => l.edited) || this.products.some(p => p.modelLevels.some(l => l.edited))) {
          const res = await this.$swal({
            title: this.$t('Warning'),
            text: this.$t('You have unsaved changes, are you sure you want to close?'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('Yes'),
            cancelButtonText: this.$t('No'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-primary ml-1',
            },
            buttonsStyling: false,
          })

          if (!res.value) {
            this.loading = false
            return
          }
        }
      }
      this.$refs.wizard.navigateToTab(0)
      this.$refs.wizard.reset(0)
      this.initialized = false
      this.open = false
      this.id = null
      this.uuidKey = null
      this.cachedModel = null
      this.name = ''
      this.type = 'FIXED'
      this.agreementType = ''
      this.year = new Date().getFullYear()
      this.description = ''
      this.levels = []
      this.errorMsg = null
      this.products = []
      this.productCount = 1
      this.title = 'Create Model'
    },

    async getModel(id) {
      this.loading = true
      this.title = 'Edit Model'

      try {
        const res = await modelsServices.getModel(id)

        if (res.data) {
          const { id: modelId, uuidKey, name, type, agreementType, products, categoryId, year, description } = res.data

          this.cachedModel = res.data
          this.products = products.map(product => ({
            ...product,
            modelLevels: product.modelLevels.map(level => ({
              ...level,
              edited: false,
            })),
          }))
          this.id = modelId
          this.uuidKey = uuidKey
          this.name = name
          this.type = type
          this.agreementType = agreementType
          this.category = categoryId
          this.year = year
          this.description = description == null ? '' : description
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async getAgreementTypes() {
      const res = await modelsServices.getAgreementTypes()
      if (res.data) {
        this.agreementTypes = res.data.values
      }
    },

    async openModal({ open, id }) {
      this.open = open

      this.getModelProducts()
      this.getAgreementTypes()
      if (id) await this.getModel(id)
      this.initialized = true
    },

    async submit() {
      if (this.products.length === 0) {
        this.errorMsg = {
          field: 'products',
          msg: 'At least one product is required',
        }
        return
      }
      if (this.products.some(p => p.modelLevels.length === 0)) {
        this.errorMsg = {
          field: 'levels',
          msg: 'At least one level per product is required',
        }
        return
      }

      const payload = {
        name: this.name,
        type: this.type,
        agreementType: this.agreementType,
        products: this.products,
      }

      this.loading = true
      try {
        await modelsServices.addModel(payload)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Model Created'),
            icon: 'CheckIcon',
            variant: 'primary',
          },
        })

        this.$bvModal.hide('add-model-modal')
        this.$emit('submit', payload)
        this.closeModal()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/master-variables.scss';
.slider {
  border: 1px solid $colour--input-border;
  border-radius: 5px;
  padding: 6px;
  span {
    color: $colour--input-text;
    padding: 0 6px;
  }
}
.form {
  overflow: hidden;
  transition: 0.35s height;
}
.container {
  min-height: 28vh;
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 8px;
}
</style>
