<template>
  <b-card
    :id="`level-${level.id}`"
    no-body
    style="box-shadow: none"
    :class="{ open: visible }"
  >
    <b-card-header
      :class="{ collapsed: !visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
    >
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center justify-content-center">
          <span class="lead collapse-title">{{ level.name }}</span>
        </div>

        <div
          v-if="showButtons"
          class="d-flex align-items-center justify-content-center"
          style="margin-right: -18px"
        >
          <!-- Save Button -->
          <feather-icon
            v-if="level.edited"
            :id="`tooltip-${index}-save`"
            class="mx-1 cursor-pointer primary"
            icon="SaveIcon"
            size="16"
            @click="saveLevel"
          />
          <b-tooltip
            v-if="level.edited"
            :target="`tooltip-${index}-save`"
            :title="$t('Save')"
            placement="top"
          />
          <!-- Delete Button -->
          <feather-icon
            v-if="showDelete"
            :id="`tooltip-${index}-delete`"
            size="16"
            icon="XIcon"
            class="cursor-pointer"
            @click="deleteLevel"
          />
          <b-tooltip
            v-if="showDelete"
            :target="`tooltip-${index}-delete`"
            :title="$t('Remove')"
            placement="top"
          />
          <div
            v-if="showCollapse"
            @click="updateVisible(!visible)"
          >
            <feather-icon
              v-if="visible"
              class="cursor-pointer ml-1"
              icon="ChevronUpIcon"
              size="16"
            />
            <feather-icon
              v-else
              class="cursor-pointer ml-1"
              icon="ChevronDownIcon"
              size="16"
            />
          </div>
        </div>
      </div>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body>
        <b-row class="mb-n1">
          <b-col
            cols="12"
            lg="8"
          >
            <b-form-group
              :label="$t('Level Name')"
              label-for="level-name"
            >
              <b-form-input
                id="level-name"
                v-model="level.name"
                type="text"
                :placeholder="$t('Level Name')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="8"
          >
            <b-form-group
              :label="type === 'FIXED' ? $t('Range') : $t('Percentage')"
              label-for="slider"
            >
              <b-row v-if="type === 'FIXED'">
                <b-col
                  cols="12"
                  lg="6"
                  class="mb-1 mb-lg-0"
                >
                  <b-input-group :prepend="$t('From')">
                    <b-form-input
                      id="level-lowerBound"
                      v-model="level.lowerBound"
                      type="number"
                      placeholder="0"
                    />
                  </b-input-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-input-group
                    size="md"
                    :prepend="$t('To')"
                  >
                    <b-form-input
                      id="level-upperBound"
                      :disabled="!level.upperBound"
                      :value="level.upperBound"
                      type="number"
                      placeholder="0"
                      @input="handleUpperBoundInput"
                    />
                  </b-input-group>
                  <b-form-checkbox
                    :checked="!level.upperBound"
                    class="mt-1"
                    @input="onUnlimitedChange"
                  >
                    {{ $t('Unlimited') }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col cols="12">
                  <b-input-group>
                    <b-form-input
                      id="level-percentage-upperBound"
                      v-model="level.upperBound"
                      type="number"
                      placeholder="0"
                    />
                    <template #append>
                      <b-input-group-text>%</b-input-group-text>
                    </template>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col cols="1"></b-col>
          <b-col
            cols="12"
            lg="3"
          >
            <b-form-group
              :label="$t('Amount')"
              label-for="amount"
            >
              <b-form-input
                id="amount"
                v-model="level.amount"
                type="number"
                placeholder="100.00"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCollapse } from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import VueSlider from 'vue-slider-component'
import '@core/scss/vue/libs/vue-slider.scss'

export default {
  components: {
    VueSlider,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    match: {
      type: Object,
      default: null,
    },
    delete: {
      type: Function,
      default: () => {},
    },
    onUnlimited: {
      type: Function,
      default: () => {},
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    showCollapse: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'FIXED',
    },
    level: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      edited: false,
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
  },
  watch: {
    level: {
      handler(newLevel, oldLevel) {
        if (!this.match) return false

        const newName = newLevel.name
        const newAmount = +newLevel.amount
        const newLowerBound = newLevel.lowerBound !== null ? +newLevel.lowerBound : null
        const newUpperBound = newLevel.upperBound !== null ? +newLevel.upperBound : null

        const matchingName = this.match.name
        const matchingAmount = this.match.amount !== null ? +this.match.amount : null
        const matchingLowerBound = this.match.lowerBound !== null ? +this.match.lowerBound : null
        const matchingUpperBound = this.match.upperBound !== null ? +this.match.upperBound : null

        if (newName !== matchingName || newAmount !== matchingAmount || newLowerBound !== matchingLowerBound || newUpperBound !== matchingUpperBound) {
          this.level.edited = true
        } else {
          this.level.edited = false
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.visible = this.isVisible
  },
  created() {
    this.collapseItemID = uuidv4()
  },
  methods: {
    handleUpperBoundInput(value) {
      const parsedValue = parseFloat(value)

      if (!isNaN(parsedValue)) {
        this.level.upperBound = parsedValue
      }
    },
    onUnlimitedChange() {
      if (this.level?.upperBound > 0) this.level.upperBound = null
      else this.level.upperBound = this.level.lowerBound + 10
    },
    deleteLevel() {
      this.$emit('delete', this.level.uuidKey)
    },
    saveLevel() {
      if (this.level.lowerBound && this.level.upperBound && +this.level.lowerBound > +this.level.upperBound) {
        this.$swal({
          title: this.$t('Error'),
          text: this.$t('From value cannot be greater than To value'),
          icon: 'error',
        })
        return
      }
      this.$emit('save', this.level)
    },
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/master-variables.scss';
.slider {
  border: 1px solid $colour--input-border;
  border-radius: 5px;
  padding: 6px;
  span {
    color: $colour--input-text;
    padding: 0 6px;
  }
}
</style>
